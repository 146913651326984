@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cinzel';
    src: url('../fonts/Cinzel-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HandWriting';
    src: url('../fonts/GreatVibes-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ephesis';
    src: url('../fonts/Ephesis-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
