.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-background-light);
    color: var(--color-primary);
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    transition: opacity .3s;
    opacity: 0;
    display: none;
}

.cookie-content {
    padding: 40px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.cookie-content p {
    font-size: 20px;
    margin-right: 80px;
}

.cookie-content button{
    font-size: 20px;
}

.cookie-content a{
    font-size: 20px;
}

@media only screen and (max-width: 1300px) {
    .cookie-content {
      flex-direction: column;
    }
  
    .cookie-content p {
        margin-right: 0 !important;
        text-align: justify;
    }
}

@media only screen and (max-width: 870px) {
    .cookie-content p {
        font-size: 16px !important;
    }
  
    .cookie-content button{
      font-size: 16px !important;
    }
  
    .cookie-content a{
      font-size: 16px !important;
    }
}