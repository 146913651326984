body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*----------------------*/
/* 1 - LOGO CONTAINER   */
/*----------------------*/

.logo-container {
  width: fit-content;
  position: relative;
}

.logo-container .logo-text {
  display: flex;
  align-items: baseline;
  font-size: 40px;
  color: #000;
  position: relative;
}

.logo-container .line {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000;
  position: absolute;
  top: 0px;
}

.logo-container .word-casa {
  margin-right: 8px;
  position: relative;
}

.logo-container .word-da {
  font-size: 28px;
  margin-bottom: 2px;
  position: relative;
  z-index: 1;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 34px solid transparent;
  border-right: 34px solid transparent;
  border-bottom: 26px solid #990000;
  position: absolute;
  top: -25px;
  left: 83%;
  transform: translateX(-50%);
  border-top-left-radius: 50% 20px;
  border-top-right-radius: 50% 20px;
}

.triangle::before,
.triangle::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -34px;
  width: 0;
  height: 0;
  border-left: 34px solid transparent;
  border-right: 34px solid transparent;
  border-top-left-radius: 50% 20px;
  border-top-right-radius: 50% 20px;
  z-index: -1;
}


.triangle::before {
  border-left: 34px solid transparent;
  border-right: 34px solid transparent;
  border-bottom: 26px solid #990000;
  top: 0;
}

.triangle::after {
  border-bottom: 26px solid #990000;
  left: -34px;
  border-bottom: 26px solid black;
  z-index: -2;
}


.logo-container .word-vila {
  margin-right: 10px;
}

.logo-container .escada {
  display: flex;
  justify-content: flex-end;
}

.logo-container .degrau {
  width: 10px;
  height: 5px;
  border-color: #333;
}

.logo-container .degrau1 {
  margin-top: 10px;
  border-bottom: 2px solid #333;
  border-right: 2px solid #333;
}

.logo-container .degrau2 {
  margin-top: 5px;
  border-bottom: 2px solid #333;
  border-right: 2px solid #333;
}

.logo-container .degrau3 {
  height: 50px;
  margin-top: -45px;
  border-bottom: 2px solid #333;
  border-right: 2px solid #333;
}

.card {
  display: none;
  width: 300px;
  height: 200px;
  perspective: 1000px;
}

.front, .back {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  backface-visibility: hidden;
  transition: transform 0.6s;
}

.front {
  background-color: #2ecc71;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transform: rotateY(0deg);
}

.back {
  background-color: #3498db;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  transform: rotateY(180deg);
}

.card:hover .front {
  transform: rotateY(-180deg);
}

.card:hover .back {
  transform: rotateY(0deg);
}