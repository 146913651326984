.cdv-flag-container {
    position: fixed;
    bottom: 5%;
    left: 30px;
    z-index: 9999;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: auto;
}

.cdv-country-icon {
    text-transform: uppercase;
    padding: 10px;
    font-size: 20px;
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.4);
}

.cdv-country-icon.active-language {
    font-weight: bold;
    background-color: var(--color-primary);
}

.cdv-country-icon:hover {
    font-weight: bold;
}

.cdv-options {
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .cdv-flag-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .cdv-country-icon.active-language + .cdv-options {
        display: flex;
        gap: 5px;
    }

    .cdv-country-icon{
        width: 20px;
        height: 20px;
        font-size: 15px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
        padding: 6px;
    }
}

@media only screen and (max-width: 2000px) {
    .cdv-country-icon {
      font-size: 15px;
      padding: 11px 8px;
    }
}

@media only screen and (max-width: 870px) {
    .cdv-flag-container{
      z-index: 9999 !important;
      bottom: 20px !important; /* Distância do fundo da janela de visualização */
      left: 12px !important;
    }
}