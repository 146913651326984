/* Estilos para o contêiner do splash screen */
.splash-container {
    z-index: 9999;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff; /* Cor de fundo do splash screen */
    opacity: 1; /* Começa totalmente visível */
    transition: opacity .5s ease-out; /* Adiciona uma transição de opacidade suave */
    background-image: url(../bg-texture.jpeg);
    background-color: transparent;
    background-position: center;
    background-repeat: repeat;
    background-size: auto;
  }

  .splash-logo-container{
    position: relative;
  }
  
  .splash-logo {
    opacity: 0;
    height: 500px; /* Altura do logotipo */
    transition: opacity .5s ease-in;
    z-index: 10;
  }

  .splash-logo.hidden{
    opacity: 1;
  }
  
  /* Estilos para ocultar o splash screen */
  .splash-container.hidden {
    opacity: 0; /* Torna o contêiner invisível */
  }  

  .splash-container.change-index {
    z-index: -1;
  }  

  .spinner {
    width: 100%;
    aspect-ratio: 1 / 1;
    border: 3px solid rgba(0, 0, 0, 0);
    border-radius: 50%;
    border-right-color: rgba(99, 0, 0, .4);
    border-top-color: rgba(99, 0, 0, .4);
    animation: spin 2s linear infinite;
    position: absolute;
    top: -30px;
    left: 0px;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 2000px) {  
    .splash-logo {
      height: 300px; 
    }
  
    .spinner{
      top: -26px;
    }
  }

  @media only screen and (max-width: 870px) {
    .splash-logo-container{
      max-width: calc(100% - 60px);
    }
  
    .splash-logo {
      max-width: 250px;
      height: auto;
    }
  }