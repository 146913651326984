.go-to-top-link {
    display: none; /* Ocultar o botão por padrão */
    position: fixed; /* Botão fixo na tela */
    bottom: calc(5% + 55px); /* Distância do fundo da janela de visualização */
    right: 30px; /* Distância da direita da janela de visualização */
    z-index: 99; /* Certifique-se de que o botão esteja acima de outros elementos */
    border: none; /* Sem borda */
    outline: none; /* Sem destaque de foco */
    background-color: var(--color-text-dark); /* Cor de fundo do botão */
    color: white; /* Cor do texto */
    cursor: pointer; /* Cursor do mouse como um ponteiro */
    padding: 18px; /* Espaçamento interno */
    border-radius: 3px; /* Bordas arredondadas */
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.4);
  }
  
  .go-to-top-link:hover {
    background-color: #333; /* Mudar a cor de fundo ao passar o mouse */
  }

  @media only screen and (max-width: 2000px) {
    .go-to-top-link {
      bottom: calc(5% + 45px);
      padding: 13px;
    }
  }

  @media only screen and (max-width: 870px) {
    .go-to-top-link {
      bottom: 65px;
      right: 12px;
      width: 36px;
      height: 42px;
      padding: 6px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
    }
  }
  